<template>
    <v-card flat class="pa-3 mt-2">
        <v-card-title>Profissionais</v-card-title>
        <v-form class="multi-col-validation">
            <v-card-text class="pt-5">
                <v-row>

                    <v-col cols="12" md="12">

                        <v-data-table :headers="headers" :items="profissionaisMudar" :search="search" sort-by="nome"
                            :footer-props="{
                                showFirstLastPage: true,
                                'items-per-page-text': 'Dados por página'
                            }">

                            <template v-slot:top>
                                <v-dialog v-model="dialog" persistent max-width="600px"
                                    transition="dialog-bottom-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" dark v-bind="attrs" v-on="on"
                                            style="margin-bottom: 20px;">
                                            Cadastro
                                        </v-btn>

                                        <v-card-title>
                                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar"
                                                single-line hide-details>
                                            </v-text-field>
                                        </v-card-title>
                                    </template>
                                    <v-card>
                                        <v-toolbar>
                                            <v-btn icon @click="fecharModal()">
                                                <v-icon>{{ icons.mdiClose }}</v-icon>
                                            </v-btn>
                                            <v-toolbar-title>Cadastro do profissional</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-btn dark text @click="alterarProfissionais()" class="primary">
                                                <v-icon>{{ icons.mdiContentSave }}</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                        <v-list three-line subheader>
                                            <v-subheader>Dados obrigatórios</v-subheader>
                                            <v-card-text ref="form">
                                                <v-form class="multi-col-validation mt-6">
                                                    <v-row>

                                                        <v-col md="6" cols="12">
                                                            <v-text-field v-model="profissional.dentista"
                                                                label="Profissional *" dense outlined
                                                                :error-messages="errorMessages" ref="profissional">
                                                            </v-text-field>
                                                        </v-col>

                                                        <v-col md="6" cols="12">
                                                            <v-text-field v-model="profissional.Cro" label="CRM *" dense
                                                                outlined :error-messages="errorMessages" ref="Cro">
                                                            </v-text-field>
                                                        </v-col>

                                                        <v-col md="6" cols="12">
                                                            <v-text-field v-model="profissional.telefone"
                                                                label="Telefone *" dense outlined
                                                                v-mask="'(##) #####-####'"
                                                                :error-messages="errorMessages" ref="profissional">
                                                            </v-text-field>
                                                        </v-col>

                                                        <v-col md="6" cols="12">
                                                            <v-text-field v-model="profissional.email" label="E-mail *"
                                                                dense outlined :error-messages="errorMessages"
                                                                ref="email">
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-form>
                                            </v-card-text>

                                        </v-list>
                                    </v-card>
                                </v-dialog>
                                <v-dialog v-model="dialogDelete" max-width="500px">
                                    <v-card class="text-center">
                                        <v-card-text class="text-h5 text-center">Deseja deletar o profissional ?
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="warning" outlined @click="closeDelete">Cancelar</v-btn>
                                            <v-btn color="success" outlined @click="deleteItemConfirm">OK</v-btn>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon small class="mr-2" @click="editItem(item)">
                                    {{ icons.mdiPencil }}
                                </v-icon>
                                <v-icon small @click="deleteItem(item)">
                                    {{ icons.mdiDelete }}
                                </v-icon>
                            </template>
                            <template v-slot:no-data>
                                Nenhum profissional encontrado
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-form>

        <v-snackbar v-model="snackbar" :color="color" class="text-center">
            {{ text }}
        </v-snackbar>
    </v-card>
</template>

<script>
import Vue from "vue";
import { ref } from '@vue/composition-api'
import { mdiClose, mdiContentSave, mdiDelete, mdiPencil } from '@mdi/js'

import { mask } from 'vue-the-mask'

export default {

    directives: { mask }
    ,

    setup() {
        const isNewPasswordVisible = ref(false)

        return {
            profissionaisA: [],
            isNewPasswordVisible,
            search: "",
            profissional: { id: null, dentista: "", Cro: "", telefone: "", email: "", idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") },
            tipos: [{ id: 1, name: "Administrador" }, { id: 2, name: "Comum" }, { id: 3, name: "Profissional" }],
            dialog: false,
            dialogDelete: false,
            newPassword: '',
            profissionaisMudar: [],
            maskCpf: "###.###.###-##",
            maskCep: "#####-###",

            response: null,
            baseUrl: 'https://viacep.com.br/ws/',

            notifications: false,
            sound: true,
            widgets: false,
            icons: {
                mdiClose,
                mdiDelete,
                mdiContentSave,
                mdiPencil
            },
            formHasErrors: false,
            errorMessages: '',
            snackbar: false,
            text: '',
            color: 'success',

            headers: [
                {
                    text: 'Profissional',
                    align: 'start',
                    value: 'dentista',
                    sortable: false
                },
                { text: 'CRM', value: 'Cro', sortable: false },
                { text: 'Telefone', value: 'telefone', sortable: false },
                { text: 'E-mail', value: 'email', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            editedIndex: -1,
            defaultProfissionais: { id: null, dentista: "", Cro: "", telefone: "", email: "", idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") },
        }
    },

    methods: {

        pegarDados: function () {
            this.$http.get("dentistas?idEmpresa=" + localStorage.getItem("idEmpresa") + "&token=" + localStorage.getItem("token"))
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        var newProfissionais = []
                        for (var i = 0; i < res.data.dados.length; i++) {
                            res.data.dados[i].token = localStorage.getItem("token")
                            newProfissionais.push(res.data.dados[i])
                        }

                        this.profissionaisMudar = newProfissionais

                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        fecharModal: function () {
            this.formHasErrors = false
            this.profissional = { id: null, dentista: "", Cro: "", telefone: "", email: "", idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") };
            this.dialog = !this.dialog
        },

        alterarProfissionais: function () {

            if (this.profissional.dentista != "" && this.profissional.Cro != "" && this.profissional.telefone != "" && this.profissional.email != "") {
                this.$http.post("cadastroDentista", this.profissional)
                    .then((res) => {
                        if (res.data.status != undefined && res.data.status == 200) {
                            if (this.profissional.id == null) {
                                this.profissionaisMudar.push(res.data.dados)
                                this.text = "Profissional cadastrado"
                                this.color = "success"
                                this.snackbar = true
                                this.dialog = !this.dialog
                            } else {
                                Object.assign(this.profissionaisMudar[this.editedIndex], this.profissional)

                                this.text = "Profissional atualizado"
                                this.color = "success"
                                this.snackbar = true
                                this.dialog = !this.dialog
                            }

                            this.pegarDados();

                        } else if (res.data.status != undefined && res.data.status == 500) {
                            this.text = res.data.msg
                            this.color = "error"
                            this.snackbar = true
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            } else {
                this.text = "Favor preencher todos campos obrigatórios"
                this.color = "warning"
                this.snackbar = true
            }
        },

        editItem(item) {
            this.editedIndex = this.profissionaisMudar.indexOf(item)
            this.profissional = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.profissionaisMudar.indexOf(item)
            this.profissional = Object.assign({}, item)
            this.dialogDelete = true

        },

        deleteItemConfirm() {
            this.profissionaisMudar.splice(this.editedIndex, 1)
            this.closeDelete()

            this.$http.post("deletarDentista", {
                id: this.profissional.id, token: localStorage.getItem("token")
            })
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.text = "Profissional deletado"
                        this.color = "success"
                        this.snackbar = true

                        this.pegarDados();

                    } else if (res.data.status != undefined && res.data.status == 500) {
                        this.text = res.data.msg
                        this.color = "error"
                        this.snackbar = true
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.profissional = Object.assign({}, this.defaultProfissionais)
                this.editedIndex = -1
            })
        },

        closeDelete() {

            this.dialogDelete = false
            this.$nextTick(() => {
                this.profissional = Object.assign({}, this.defaultProfissionais)
                this.editedIndex = -1


            })
        },

    },

    created() {

    },

    beforeMount() {
        var logado = localStorage.getItem("login")
        if (logado != undefined && logado == "true") {
            this.pegarDados()
        } else {
            this.$router.push('login')
        }
    },

    watch: {
        name() {
            this.errorMessages = ''
        },

        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
}
</script>
